<template>
  <div class="container">
    <!-- 注册成功 顶部提示语 金额 按钮 -->
    <div class="top" v-if="!backFlag">
      <div class="card-box">
        <img
          class="check-mark"
          src="../assets/images/productRecomedNew/check_mark.png"
        />
        <span>恭喜您！您已成功通过{{ productName }}初审</span>
      </div>
      <div class="card-countent">
        <div class="title">预估总额度（元）</div>
        <count-to
          :start-val="0"
          :end-val="96000"
          :duration="3600"
          class="amount u-text-center"
        />
        <div class="features-text">
          <span>额度高</span>
          <span>到账快</span>
          <span>超灵活</span>
        </div>
        <div class="features-sub-text">
          <span>申请多个产品，放款率>98%</span>
        </div>
        <div class="btn-box">
          <button class="btn-submit" @click="handleSubmit">{{ btnTxt }}</button>
        </div>
      </div>
    </div>
    <div class="top-back" v-else>
      <div class="blank"></div>
      <div class="card-countent">
        <div class="title">预估总额度（元）</div>
        <div class="amount-btn">
          <count-to
            :start-val="0"
            :end-val="200000"
            :duration="3600"
            class="amount u-text-center"
          />
          <button class="btn-submit" @click="handleSubmit">{{ btnTxt }}</button>
        </div>
        <div class="features-text">
          <span>额度高</span>
          <span>到账快</span>
          <span>超灵活</span>
        </div>
        <div class="features-sub-text">申请多个产品，放款率>98%</div>
      </div>
    </div>

    <!-- 两个产品的列表 -->
    <div>
      <div class="card-list" :style="{ height: list.length * 3.8 + 'rem' }">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="product-item"
          @click="handleGoLink(item)"
        >
          <div class="u-flex u-row-between u-col-bottom">
            <div class="u-flex u-col-center">
              <img class="product-logo" :src="item.logo" />
              <span class="product-title">{{ item.productName }}</span>
            </div>
            <span class="servicer">已服务{{ item.loanCount }}人</span>
          </div>

          <div class="product-item-middle u-flex u-row-between u-col-center">
            <div class="product-item-middle-left">
              <count-to
                :start-val="0"
                :end-val="item.loanAmount"
                :duration="3600"
                class="number-span"
              />
              <small>元</small>
              <h5 class="product-tips">最高可借金额</h5>
            </div>
            <div class="product-item-middle-center">
              <span class="number-span">
                {{ item.loanPeriod }}<small>月</small>
              </span>
              <h5 class="product-tips">借款期限</h5>
            </div>
            <div class="">
              <span class="number-span">
                {{ item.loanRate }}<small>%</small>
              </span>
              <h5 class="product-tips">综合年化</h5>
            </div>
          </div>

          <div class="u-flex u-row-between u-col-center">
            <div class="u-flex u-col-center">
              <van-tag
                v-for="(i, idx) in item.productTagList"
                :key="idx"
                color="#FFF4E6"
                text-color="#FD9A15"
              >
                {{ i }}
              </van-tag>
            </div>
            <button class="prodact-btn">去申请</button>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="list.length <= 2">
        <div class="show-txt">
          <span>申请流程</span>
        </div>
        <div class="apply-process">
          <span class="first">智能审核</span>
          <span class="middle"></span>
          <span class="last">人工电话复核</span>
        </div>
      </div>
      <div></div>
      <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-if="isFinsh">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="product-item"
              @click="handleGoLink(item)"
            >
              <div class="u-flex u-row-between u-col-bottom">
                <div class="u-flex u-col-center">
                  <img class="product-logo" :src="item.logo" />
                  <span class="product-title">{{ item.productName }}</span>
                </div>
                <span class="servicer">已服务{{ item.loanCount }}人</span>
              </div>

              <div
                class="product-item-middle u-flex u-row-between u-col-center"
              >
                <div class="product-item-middle-left">
                  <count-to
                    :start-val="0"
                    :end-val="item.loanAmount"
                    :duration="3600"
                    class="number-span"
                  />
                  <small>元</small>
                  <h5 class="product-tips">最高可借金额</h5>
                </div>
                <div class="product-item-middle-center">
                  <span class="number-span">
                    {{ item.loanPeriod }}<small>月</small>
                  </span>
                  <h5 class="product-tips">借款期限</h5>
                </div>
                <div class="">
                  <span class="number-span">
                    {{ item.loanRate }}<small>%</small>
                  </span>
                  <h5 class="product-tips">综合年化</h5>
                </div>
              </div>

              <div class="u-flex u-row-between u-col-center">
                <div class="u-flex u-col-center">
                  <van-tag
                    v-for="(i, idx) in item.productTagList"
                    :key="idx"
                    color="#FFF4E6"
                    text-color="#FD9A15"
                  >
                    {{ i }}
                  </van-tag>
                </div>
                <button class="prodact-btn">去申请</button>
              </div>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>  -->
    </div>
    <!-- 提示浏览器打开遮罩层 -->
    <SharePop
      :show="showPop"
      @change="handlePopChange"
      v-if="this.popData.productName"
    />
    <van-popup
      v-model="isShowPop"
      position="bottom"
      closeable
      @close="handelClosePop"
      v-if="this.popData.productName"
    >
      <div class="pop-countent">
        <div class="title">
          <span class="span-icon"></span>
          <span class="title-text"
            >恭喜您已通过{{ popData.productName }}初审</span
          >
        </div>
        <div class="countent">
          <div class="num">{{ popData.loanAmount }}元</div>
          <div class="tip">额度由{{ popData.productName }}提供</div>
        </div>
        <div class="agree-txt">
          <van-checkbox
            v-model="checked"
            class="check-box"
            shape="square"
          ></van-checkbox>
          <span class="txt"
            >我已阅读并同意<i @click="handleProtocol(9)"
              >《个人信息查询授权书》</i
            ></span
          >
        </div>
        <div class="btn-pop" @click="handlePopBtn">去提现</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import SharePop from "@/components/SharePop";
import * as API_Common from "@/api/common.js";

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
export default {
  name: "ProductRecomed",
  components: {
    CountTo,
    SharePop,
  },
  data() {
    return {
      channel: this.$route.query.source,
      isFinsh: true,
      backFlag: window.localStorage.getItem("backFlag"),
      isShowPop:
        !window.localStorage.getItem("closePop") &&
        window.localStorage.getItem("backFlag"),
      list: [],
      checked: false,
      params: {
        pageNum: 1,
        pageSize: 5,
      },
      loading: false,
      finished: true,
      refreshing: true,
      total: 0,
      downAppUrl: "",
      showPop: false,
      productName: "",
      popData: {},
      recommendProduct: {},
      btnTxt: "立即提现",
    };
  },
  created() {
    this.getDownloadUrl();
    this.onRefresh();
    this.getChannleProductList();
    this.getPopProduct();
    if (
      window.localStorage.getItem("closePop") == null &&
      window.localStorage.getItem("backFlag")
    ) {
      this.isShowPop = true;
    }
  },
  methods: {
    // 关闭弹窗 如果点击关闭按钮之后，则以后不再弹窗
    handelClosePop() {
      this.isShowPop = false;
      window.localStorage.setItem("closePop", "ture");
      window.localStorage.removeItem("backFlag");
    },
    handlePopBtn() {
      if (!this.checked) {
        this.$toast("请勾选同意个人信息查询授权书");
        return;
      }
      API_Common.buried({
        productId: this.popData.productId,
        showPage: 5,
      }).then((res) => {
        if (200 === res.code) {
          window.localStorage.setItem("closePop", "ture");
          window.localStorage.removeItem("backFlag");
          window.location.href = this.popData.linkUrl;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    handleSubmit() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.popData.isShieldCity) {
          window.location.href = this.downAppUrl;
        } else {
          API_Common.buried({
            productId: this.recommendProduct.id,
            showPage: 5,
          }).then((res) => {
            if (200 === res.code) {
              window.localStorage.setItem("backFlag", "true");
              window.location.href = this.recommendProduct.linkUrl;
            } else {
              this.$toast.fail(res.msg);
            }
          });
        }
      }
    },

    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        }
      });
    },

    getProductList() {
      API_Common.queryProductList(this.params).then((res) => {
        if (200 === res.code) {
          if (res.data.list.length > 0) {
            this.total = res.data.total;
            this.params.pageNum++;

            res.data.list.map((item) => {
              item.loanAmount = Number(item.loanAmount);
            });

            this.list.push(...res.data.list);
            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
            this.isFinsh = true;
          } else {
            this.finished = true;
            this.isFinsh = false;
          }
        } else {
          this.$toast.fail("获取产品列表失败");
        }
      });
    },
    // 根据渠道code读取产品推荐信息 将权重最大的进行显示
    getChannleProductList() {
      API_Common.getChannleProductList(this.channel).then((res) => {
        if (res.code === 200) {
          let dataArr = res.data;
          let maxItem = dataArr.reduce(function (prev, current) {
            return prev.sort > current.sort ? prev : current;
          });
          this.productName = maxItem.name;
          this.recommendProduct = maxItem;
        }
      });
    },
    // 根据渠道code和进行到哪一步查询配置H5弹窗产品权重最大的值
    getPopProduct() {
      API_Common.getPopItem(this.channel).then((res) => {
        if (res.code === 200) {
          this.popData = res.data;
          if (res.data.isShieldCity) {
            this.btnTxt = "立即下载";
            this.productName = "融e钱包";
          }
        }
      });
    },
    handleProtocol(pageType) {
      window.location.href = "/protocol.html?pageType=" + pageType;
      localStorage.setItem("isClickAuth", "YES");
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
        this.params.pageNum = 1;
      }
      this.getProductList();
    },

    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    handlePopChange(show) {
      this.showPop = show;
    },

    handleGoLink(item) {
      API_Common.buried({
        productId: item.productId,
        showPage: 5,
      }).then((res) => {
        console.log(res);
        if (200 === res.code) {
          setTimeout(function () {
            window.localStorage.setItem("backFlag", "true");

            window.location.href = item.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  // padding: 0.28rem 0.4rem 0.2rem;
  height: 100vh;
  // background: url("../assets/images/product_recomed_bg.png") no-repeat center
  //   center;
  // background-size: 100%;
  background-color: #f4f5f6;
  overflow: auto;
}

.top {
  background: url("../assets/images/productRecomedNew/bg-up.png") no-repeat
    center center;
  background-size: cover;
  height: 334px;
  .card-box {
    padding-top: 26px;
    display: flex;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a67d2;
    line-height: 24px;
    justify-content: center;
  }
  .card-countent {
    width: 95vw;
    // height: 40vh;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    // display: flex;
    // flex-direction: column;

    // justify-content: center;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding-top: 10px;

      color: #000000;
      line-height: 20px;
    }
    .amount {
      font-size: 1rem;
      color: #1262ff;
      line-height: 1.32rem;
    }
    .features-text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ec7e12;
      line-height: 17px;
      span {
        display: inline-block;
        background: #fff9e9;
        border-radius: 2px;
        border: 1px solid #f0ba85;
        margin-right: 6px;
        padding: 2px 6px;
      }
    }
    .features-sub-text {
      padding: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #949494;
      line-height: 17px;
      span::after {
        content: "";
        width: 28px;
        height: 2px;
        background: linear-gradient(
          270deg,
          rgba(238, 238, 238, 0) 0%,
          #d8d8d8 100%,
          #d8d8d8 100%
        );
        border-radius: 2px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      span::before {
        content: "";
        width: 28px;
        height: 2px;
        background: linear-gradient(
          270deg,
          #d8d8d8 0%,
          #d8d8d8 0%,
          rgba(238, 238, 238, 0) 100%
        );
        border-radius: 2px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    //.auto-txt {
    //  font-size: 12px;
    //  font-family: PingFangSC-Regular, PingFang SC;
    //  font-weight: 400;
    //  color: #bfbfbf;
    //  line-height: 17px;
    //  padding-bottom: 10px;
    //}
  }
}
.top-back {
  background: url("../assets/images/productRecomedNew/bg-up.png") no-repeat
    center center;
  background-size: 100%;
  height: 206px;
  .blank {
    padding-bottom: 0.39rem;
  }

  .card-countent {
    width: 95vw;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 0 auto;
    padding-left: 20px;
    .title {
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding-top: 10px;
      color: #000000;
      line-height: 20px;
    }
    .amount-btn {
      display: flex;
      justify-content: space-between;
      .amount {
        font-size: 0.86rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1262ff;
        line-height: 1.2rem;
      }

      .btn-submit {
        width: 2.5rem;
        height: 0.86rem;
        background: linear-gradient(90deg, #f5bf68 0%, #ec8748 100%);
        border-radius: 4px;
        display: block;
        font-size: 0.32rem;
        font-weight: 500;
        color: #ffffff;
        // margin: 0.3rem auto;
      }
    }

    .features-text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ec7e12;
      line-height: 17px;
      span {
        display: inline-block;
        background: #fff9e9;
        border-radius: 2px;
        border: 1px solid #f0ba85;
        margin-right: 6px;
        padding: 2px 6px;
      }
    }
    .features-sub-text {
      padding: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #949494;
      line-height: 17px;
    }
    //.auto-txt {
    //  font-size: 12px;
    //  font-family: PingFangSC-Regular, PingFang SC;
    //  font-weight: 400;
    //  color: #bfbfbf;
    //  line-height: 17px;
    //  padding-bottom: 10px;
    //}
  }
}
.pop-countent {
  width: 100vw;
  padding-left: 0.36rem;

  .title {
    font-size: 0.41rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 0.57rem;
    padding-top: 0.57rem;
    .span-icon {
      background: url("../assets/images/productRecomedNew/check_mark.png")
        no-repeat;
      // no-repeat center center;
      background-size: 100%;
      display: inline-block;
      width: 0.45rem;
      height: 0.45rem;
      margin-right: 10px;
    }
  }
  .countent {
    height: 1.86rem;
    background: #f5f6f9;
    border-radius: 0.11rem;
    width: 90vw;
    // filter: blur(0px);
    .num {
      font-size: 0.45rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 0.64rem;
      padding-top: 0.34rem;
      padding-left: 0.34rem;
    }
    .tip {
      font-size: 0.36rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 0.5rem;
      padding-left: 0.34rem;
    }
  }
  .agree-txt {
    font-size: 0.23rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.32rem;
    display: flex;
    align-items: center;
    .check-box {
      display: inline-block;
      padding: 10px;
    }
    .txt {
      i {
        color: #437eff;
      }
    }
  }
  .btn-pop {
    text-align: center;
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.02rem;
    background: url("../assets/images/productRecomedNew/btn-pop.png") no-repeat;
    // no-repeat center center;
    width: 6.8rem;
    background-size: 100%;
    height: 1.02rem;
    margin-bottom: 20px;
  }
}
.card-list {
  margin: 20px 34px 0 39px;
  background: url("../assets/images/productRecomedNew/bg-down.png") no-repeat;
  // no-repeat center center;
  width: 95vw;
  background-size: 100%;
  height: 382px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
}
.card-footer {
  text-align: center;
  padding-top: 0.45rem;
  padding-bottom: 0.93rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.45rem;
  width: 80vw;
  margin: 0 auto;
  .show-txt {
    span::before {
      content: "";
      width: 50px;
      height: 1px;
      border-top: 1px solid #dddddd;
      vertical-align: middle;
      display: inline-block;
      margin-right: 10px;
    }
    span::after {
      content: "";
      display: inline-block;
      width: 50px;
      height: 1px;
      border-top: 1px solid #dddddd;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .apply-process {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.48rem;
    .first::before {
      content: "";
      display: inline-block;
      background: url("../assets/images/productRecomedNew/examine.png")
        no-repeat center center;
      background-size: cover;
      height: 0.52rem;
      width: 0.52rem;
      vertical-align: middle;
      margin-right: 0.18rem;
    }
    .middle {
      display: inline-block;
      background: url("../assets/images/productRecomedNew/next.png") no-repeat
        center center;
      background-size: cover;
      height: 0.57rem;
      width: 0.59rem;
    }
    .last::before {
      content: "";
      display: inline-block;
      background: url("../assets/images/productRecomedNew/examine.png")
        no-repeat center center;
      background-size: cover;
      height: 0.52rem;
      width: 0.52rem;
      vertical-align: middle;
      margin-right: 0.18rem;
    }
  }
}
.check-mark {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.16rem;
}
.btn-box {
  padding-bottom: 10px;
}
.btn-submit {
  width: 4rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f5bf68 0%, #ec8748 100%);
  border-radius: 4px;
  display: block;
  font-size: 0.36rem;
  font-weight: 500;
  color: #ffffff;
  margin: 0.3rem auto;
}

.tips {
  margin: 0.35rem auto 0;
  font-size: 0.28rem;
}

.product-item {
  // background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.32rem 0.28rem 0.3rem 0.3rem;
  margin-bottom: 0.24rem;
}

.product-logo {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.12rem;
  border-radius: 50%;
}

.product-title {
  font-size: 0.28rem;
  font-weight: 600;
  color: #3a3a3a;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.servicer {
  word-break: keep-all;
  font-size: 0.24rem;
  color: #ffbb31;
}

.product-item-middle {
  margin: -0.27rem 0 0.24rem;
}

.product-item-middle-left {
  color: #fb8d0b;
}

.product-item-middle small {
  font-size: 0.24rem;
}

.number-span {
  font-size: 0.48rem;
  font-weight: 600;
  margin-right: -0.13rem;
  word-break: keep-all;
}

.product-item-middle-center {
  color: #3a3a3a;
}

.product-item-middle-left .product-tips {
  margin-top: -0.15rem;
}

.product-tips {
  font-size: 0.24rem;
  color: #c3c2c6;
  font-weight: 400;
}

.prodact-btn {
  width: 1.44rem;
  height: 0.6rem;
  background: linear-gradient(90deg, #ffbb31, #fb8302);
  border-radius: 0.36rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ffffff;
}

.van-tag {
  margin-right: 0.1rem;
}

::v-deep {
  .van-pull-refresh__head,
  .van-list__loading,
  .van-loading__text,
  .van-loading__spinner,
  .van-list__finished-text {
    font-size: 0.28rem;
    color: #fff !important;
  }
}
</style>
