import request from "@/utils/request";

export function isJump(params) {
  return request({
    url: `/channel/judge`,
    method: "GET",
    headers: {
      ...params,
      loading: false,
      unDecode: 1,
    },
  });
}

export function sendSmsCode(mobile) {
  return request({
    url: `/sendCode`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
    params: {
      mobile,
    },
  });
}

export function getContent(data) {
  return request({
    url: `/content/get`,
    method: "POST",
    headers: {
      loading: false,
      unDecode: 1,
    },
    data,
  });
}

export function getRegions(id) {
  return request({
    url: `/regions/${id}/children`,
    method: "GET",
    headers: {
      loading: false,
      unDecode: 1,
    },
  });
}

export function login(params) {
  return request({
    url: `/h5Login`,
    method: "POST",
    headers: {
      channel: params.channel,
      unDecode: 1,
    },
    data: {
      mobile: params.mobile,
      code: params.code,
      // deviceModel: getMobSysInfo(),
    },
  });
}

export function loanApply(data) {
  return request({
    url: `/car/loan/apply`,
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function getDownloadUrl(channel) {
  return request({
    url: `/channel/download`,
    method: "GET",
    headers: {
      channel,
      unDecode: 1,
    },
  });
}

export function checkIdCard(data) {
  return request({
    url: `/user/checkIdCard`,
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function queryProductList(data) {
  return request({
    url: "/product/api/h5CpaProductPageList",
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function buried(data) {
  return request({
    url: "/product/api/addLoanRecord",
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function queryFormItem() {
  return request({
    url: "/registerDataType",
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}

export function submitAnEntry(data) {
  return request({
    url: "/assetAndApplyV2",
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function submitAnEntryTouTiao(data) {
  return request({
    url: "/touTiaoRegisterAndApply",
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}

export function queryRiskWarning(channelNo) {
  return request({
    url: `/channel/landingPageAttr/${channelNo}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
// 根据渠道code获取推荐产品
export function getChannleProductList(code) {
  return request({
    url: `/channel/queryChannleProduct/${code}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
// 根据渠道code和step值获取弹窗配置的最高权重
export function getPopItem(code) {
  return request({
    url: `/product/api/getpopitem?code=${code}&step=3`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
//获取是否屏蔽城市
export function getIsShieldCity(code) {
  return request({
    url: `/channel/isShieldCity?source=${code}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
//获取信业帮url phone channelCode
export function getXYBLinkUrl(data) {
  return request({
    url: "/agency/xy/checkIsExist",
    method: "POST",
    headers: {
      needToken: true,
      unDecode: 1,
    },
    data,
  });
}
export function getRegister2Logo(source) {
  return request({
    url: `/channel/logo/${source}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
export function getPopupProductList(source) {
  return request({
    url: `/product/api/getPopControllerList?code=${source}&step=2`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
export function channelIncomingControl(source) {
  return request({
    url: `/channel/getLandingPageByChannel?source=${source}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
export function getUser2WhereApi() {
  let userId = window.localStorage.getItem("userId");
  return request({
    url: `/channel/getUser2WhereApiV2?userId=${userId}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}

export function getApiResultsInfo(data) {
  return request({
    url: `/api/agency/access/getInfo`,
    method: "POST",
    headers: {
      unDecode: 1,
    },
    data,
  });
}
export function callPushData(data) {
  return request({
    url: `/api/agency/access/callPushData`,
    method: "POST",
    headers: {
      unDecode: 1,
    },
    data,
  });
}
export function delayModel8SUpdate(source, second, tid, index) {
  return request({
    url: `/channel/addStagRecordWithStep/${source}/${second}/${tid}/${index}`,
    method: "POST",
    headers: {
      loading: false,
      unDecode: 1,
    },
  });
}
export function delayModel9SUpdate(source, second, tid, index, thirdPartId) {
  return request({
    url: `/channel/addStagRecordWithStep/${source}/${second}/${tid}/${index}/${thirdPartId}`,
    method: "POST",
    headers: {
      loading: false,
      unDecode: 1,
    },
  });
}
//惠逸花 产品申请
export function getHYHProductData(data) {
  return request({
    url: `/api/agency/hmc/push`,
    method: "POST",
    headers: {
      loading: false,
      unDecode: 1,
    },
    data,
  });
}
//腰部数据点击同意协议埋点
export function middleDataEventTracking(pushRecordId, thirdPartyId) {
  return request({
    url: `agency/manualGrant?pushRecordId=${pushRecordId}&thirdPartyId=${thirdPartyId}`,
    method: "GET",
    headers: {
      unDecode: 1,
    },
  });
}
